import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import Button from "@mui/material/Button";
import { useEffect, useRef } from "react";
import { useMap } from "./useMap";
import "ol/ol.css";
import { NftRequestProps } from "../../types";

export type MapModalProps = {
  open: boolean;
  request: NftRequestProps;
  onClose: () => void;
};

export const MapModal = (props: MapModalProps) => {
  const mapRef = useRef<HTMLDivElement>(null);
  const { map, setCenter, drawRegion } = useMap();

  useEffect(() => {
    if (mapRef.current) {
      map.setTarget(mapRef.current);
      map.updateSize();
    }
  }, [map]);

  const downloadKMLFile = () => {
    const text =
      '<?xml version="1.0" encoding="UTF-8"?>\n' +
      '<kml xmlns="http://www.opengis.net/kml/2.2">\n' +
      "  <Placemark>\n" +
      "    <name>The Pentagon</name>\n" +
      "    <Polygon>\n" +
      "      <altitudeMode>relativeToGround</altitudeMode>\n" +
      "      <outerBoundaryIs>\n" +
      "        <LinearRing>\n" +
      "          <coordinates>\n" +
      ` ${props.request.markers
        .map((i) => {
          return `${i.longitude},${i.latitude},0`;
        })
        .join("\n")}` +
      "\n" +
      "          </coordinates>\n" +
      "        </LinearRing>\n" +
      "      </outerBoundaryIs>\n" +
      "    </Polygon>\n" +
      "  </Placemark>\n" +
      "</kml>";

    const blob = new Blob([text], {
      type: "application/vnd.google-earth.kml+xml",
    });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.download = "coordinates.kml";
    link.href = url;
    link.click();
  };

  useEffect(() => {
    function handleResize() {
      setTimeout(function () {
        // Update container size
        var coordinates: number[][] = [];
        if (props.request.markers) {
          coordinates = props.request.markers.map((i) => {
            return [i.longitude, i.latitude];
          });
        }

        if (mapRef.current) {
          map.setTarget(mapRef.current);
          map.updateSize();
          setCenter(coordinates[0]);
          drawRegion(coordinates);
        }
      }, 300);
    }

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [props.open, map]);

  return (
    <Dialog open={props.open} fullWidth maxWidth="md">
      <DialogTitle>Mapa</DialogTitle>
      <DialogContent dividers>
        <div
          className="map-container"
          style={{ width: "100%", height: "80vh" }}
        >
          <div
            id="map"
            ref={mapRef}
            style={{ width: "100%", height: "100%" }}
          ></div>
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="secondary"
          autoFocus
          onClick={downloadKMLFile}
        >
          Descargar KML
        </Button>
        <Button
          variant="contained"
          color="secondary"
          autoFocus
          onClick={props.onClose}
        >
          Cerrar
        </Button>
      </DialogActions>
    </Dialog>
  );
};
